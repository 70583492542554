<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Piesa</v-table-th>
        <v-table-th>Marca</v-table-th>
        <v-table-th>Model</v-table-th>
        <v-table-th>Stock</v-table-th>
        <v-table-th>Pret</v-table-th>
        <v-table-th class="text-center">Actiuni </v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ row.name }}
        </v-table-td>
        <v-table-td>
          {{ row.car.make.name }}
        </v-table-td>
        <v-table-td>
          {{ row.car.name }}
        </v-table-td>
        <v-table-td>
          {{ row.stock }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.price) }}
        </v-table-td>
        <v-table-td class="text-center">
          <router-link
            :to="{
              name: 'products.edit',
              params: { id: row.id },
            }"
            class="link"
          >
            Modifica
          </router-link>
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VTable from "./VTable.vue";
import VTableTd from "./VTableTd.vue";
import VTableTh from "./VTableTh.vue";
import VTbody from "./VTbody.vue";
import VThead from "./VThead.vue";
export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
  },

  props: {
    rows: {
      type: Array,
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
