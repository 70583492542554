<template>
  <app-layout>
    <page-header>
      Produse
      <template #end><products-index-button-add /></template>
    </page-header>
    <page-section>
      <form class="flex" @submit.prevent="index">
        <div class="mr-2 ml-0">
          <v-label>Nume</v-label>
          <v-input type="text" v-model="query.name" placeholder="Usa" />
        </div>

        <button class="btn self-end">Filtreaza</button>
      </form>

      <v-card class="mt-2">
        <loader v-if="loading" class="my-5" />

        <template v-else>
          <template v-if="totalRows">
            <products-index-table :rows="rows"></products-index-table>
            <pagination
              @change:page="index($event)"
              :total="meta.total"
              :current_page="meta.current_page"
              :from="meta.from"
              :to="meta.to"
              :next_page_url="links.next"
              :prev_page_url="links.prev"
            />
          </template>

          <no-results v-else class="m-5" />
        </template>
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import productsApi from "../api/resources/productsApi";
import ProductsIndexTable from "../components/ProductsIndexTable.vue";
import VCard from "../components/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import ProductsIndexButtonAdd from "../components/ProductsIndexButtonAdd.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import VInput from "../components/VInput.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";
import VLabel from "../components/VLabel.vue";

export default {
  components: {
    ProductsIndexTable,
    AppLayout,
    VCard,
    ProductsIndexButtonAdd,
    Loader,
    Pagination,
    NoResults,
    VInput,
    PageHeader,
    PageSection,
    VLabel,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      query: {
        name: "",
      },
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    changePage(page) {
      this.query.page = page;
      this.index();
    },

    index(page = 1) {
      this.loading = true;
      productsApi
        .index({ page, ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
