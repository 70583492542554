<template>
  <td class="p-4 whitespace-nowrap text-sm font-medium">
    <slot></slot>
  </td>
</template>

<script>
export default {};
</script>

<style></style>
