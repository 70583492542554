<template>
  <router-link class="btn-primary" :to="{ name: 'products.create' }">
    <v-button-icon>
      <PlusIcon />
    </v-button-icon>
    Adauga produs
  </router-link>
</template>

<script>
import VButtonIcon from "./VButtonIcon.vue";
import { PlusIcon } from "@heroicons/vue/solid";
export default {
  components: { VButtonIcon, PlusIcon },
};
</script>

<style></style>
